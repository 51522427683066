import { checkAuthStatus } from '@/services/account.service';
import { toaster } from '@/utils/toast/toast.js';
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/candidate/home/Home.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/candidat',
    name: 'home-candidate',
    component: () => import('@/views/candidate/home/Home.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/recruteur',
    name: 'home-recruiter',
    component: () => import('@/views/candidate/home/Home.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recherche',
    name: 'search',
    component: () => import('@/views/candidate/search/Search.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/offre-d-emplois/:id',
    name: 'joboffer',
    component: () => import('@/views/candidate/joboffer/Joboffer.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/chat-ia',
    name: 'messaging',
    component: () => import('@/views/all/messaging/Messaging.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/inscription',
    name: 'register',
    component: () => import('@/views/candidate/register/Register.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/connexion',
    name: 'login',
    component: () => import('@/views/candidate/login/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/reseau-social',
    name: 'social',
    component: () => import('@/views/all/social-media/Reseaux.vue'),
    meta: { requiresAuth: false },
    // aucune authentification requise
    // la fonction pour ajouter un post/commentaire est protégée par v-if="isLoggedIn"
  },
  {
    path: '/tarifs',
    name: 'prices',
    component: () => import('@/views/candidate/prices/Prices.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/actualites',
    name: 'blog',
    component: () => import('@/views/all/blog/Blog.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/actualite-section/:sectiontype',
    name: 'blog-section',
    component: () => import('@/views/all/blog-section/BlogSection.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/actualite/:sectiontype/:articletheme/:slug/:articleid',
    name: 'article',
    component: () => import('@/views/all/article/Article.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/candidate/contact/Contact.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/candidate/faq/Faq.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/favoris',
    name: 'favorite',
    component: () => import('@/views/candidate/favorite/Favorite.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/alertes',
    name: 'Alerts',
    component: () => import('@/views/all/alerts/AlertsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tableau-de-bord',
    name: 'dashboard',
    component: () => import('@/views/candidate/dashboard/Dashboard.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/candidatures',
    name: 'candidatures',
    component: () => import('@/views/candidate/candidatures/Candidatures.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/abonnements/:id',
    name: 'subscription',
    component: () =>
      import('@/views/candidate/subscriptions/Subscriptions.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/cgu',
    name: 'cgu',
    component: () => import('@/views/all/cgu/CGU.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/politique-confidentialite',
    name: 'politique-confidentialite',
    component: () =>
      import(
        '@/views/all/politique-confidentialite/PolitiqueConfidentialite.vue'
      ),
    meta: { requiresAuth: false },
  },
  {
    path: '/cgv',
    name: 'cgv',
    component: () => import('@/views/all/cgv/CGV.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/mentions-legales',
    name: 'mentions-legales',
    component: () => import('@/views/all/mentions-legales/MentionsLegales.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('@/views/candidate/profil/Profil.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/aperçu-profil',
    name: 'profil-preview',
    component: () =>
      import('@/views/candidate/profile-preview/ProfilePreview.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/parametres',
    name: 'settings',
    component: () => import('@/views/all/settings/Settings.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profil/edition',
    name: 'profile-edition',
    component: () => import('@/views/all/edition-profile/editionProfile.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/recruteur/profil',
    name: 'profil-recruiter', // nom profil déjà existant attention!!
    component: () => import('@/views/recruiter/profil/Profil.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/a-propos',
    name: 'about',
    component: () => import('@/views/all/about/About.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/recruteur/tarifs',
    name: 'recruiter-prices',
    component: () => import('@/views/recruiter/prices/Prices.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recruteur/abonnements/:id',
    name: 'recruiter-subscription',
    component: () =>
      import('@/views/recruiter/subscriptions/Subscriptions.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/achat',
    name: 'PurchaseRecap',
    component: () => import('@/views/all/purchase-funnel/TunnelAchat.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/paiement-valide',
    name: 'StripeSuccess',
    component: () => import('@/views/all/stripe/StripeSuccess.vue'),
    meta: { requiresAuth: false }, // Changer en "true" lorsque la page sera terminée
  },
  {
    path: '/paiement-refuse',
    name: 'StripeCancel',
    component: () => import('@/views/all/stripe/StripeCancel.vue'),
    meta: { requiresAuth: false }, // Changer en "true" lorsque la page sera terminée
  },
  {
    path: '/reinitialisation-mot-de-passe',
    name: 'password-reset',
    component: () =>
      import('@/views/all/password-reset/PasswordResetRequest.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/reinitialisation-mot-de-passe-confirme',
    name: 'password-reset-confirm',
    component: () =>
      import('@/views/all/password-reset/PasswordResetChange.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/all/404/404.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/recruteur/tableau-de-bord',
    name: 'recruiter-dashboard',
    component: () => import('@/views/recruiter/dashboard/Dashboard.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recruteur/inscription',
    name: 'recruiter-register',
    component: () => import('@/views/recruiter/register/Register.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/recruteur/aperçu-profil',
    name: 'recruiter-profil-preview',
    component: () =>
      import('@/views/recruiter/profilpreview/RecruiterProfilPreview.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/utilisateur/:id',
    name: 'recruiter-candidate-:id',
    component: () =>
      import('@/views/recruiter/candidate-page/CandidatePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/recruteur/offres',
    name: 'recruiter-offer',
    component: () => import('@/views/recruiter/all-offer/Offer.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/communaute',
    name: 'recruiter-search',
    component: () => import('@/views/recruiter/search/Search.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/recruteur/connexion',
    name: 'recruiter-login',
    component: () => import('@/views/recruiter/login/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/recruteur/favoris',
    name: 'recruiter-favorite',
    component: () => import('@/views/recruiter/favorite/Favorite.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recruteur/offre/:offerId/candidatures',
    name: 'recruiter-offer-applications-id',
    component: () =>
      import('@/views/recruiter/offer-applications/OfferApplications.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recruteur/offre/redaction',
    name: 'recruiter-offer-new',
    component: () => import('@/views/recruiter/new-offer/NewOffer.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recruteur/messagerie',
    name: 'recruiter-messaging',
    component: () =>
      import('@/views/all/messaging-websocket/MessagingPage.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/messagerie',
    name: 'call',
    component: () =>
      import('@/views/all/messaging-websocket/MessagingPage.vue'),
    meta: { requiresAuth: true, role: 'applicant' },
  },
  {
    path: '/recruteur/offre/:offerId/candidature/:candidateId',
    name: 'recruiter-candidature',
    component: () => import('@/views/recruiter/candidature/Candidature.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/recruteur/offre/:offerId/aperçu',
    name: 'OfferPreview',
    component: () => import('@/views/recruiter/offer-preview/OfferPreview.vue'),
    meta: { requiresAuth: true, role: 'recruiter' },
  },
  {
    path: '/merci',
    name: 'ThankYou',
    component: () => import('@/views/all/thanks/ThankYou.vue'),
  },
  {
    path: '/amis',
    name: 'friends',
    component: () => import('@/views/all/friends/Friends.vue'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: '/applicant',
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, _, next) => {
  try {
    const isLoggedIn = await checkAuthStatus();
    const userRole = store.getters.userRole;

    if (to.meta.requiresAuth && !isLoggedIn) {
      next({ name: 'login' });
    } else if (to.meta.type_user && to.meta.type_user !== userRole) {
      next({ name: 'home' });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toaster.showErrorPopup(
        "Accès refusé : vous n'avez pas les autorisations nécessaires.",
        {
          timeout: 5000,
        }
      );
    } else {
      next();
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification de l'authentification:",
      error
    );
    next({ name: 'error' });
  }
});

export default router;
