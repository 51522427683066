<template>
  <nav>
    <div :class="['navbar', userTypeClass]">
      <div class="img-container" @click.stop="gotoPage('/')">
        <div class="img-container-circle">
          <img src="@/assets/logo-tb.svg" alt="logo" />
        </div>
        <div class="beta-section">
          <h2>Bêta</h2>
        </div>
      </div>

      <!-- switch visible sur homepage.com uniquement -->
      <div v-if="!isLoggedIn" class="view-switch">
        <button @click="toggleView">
          Voir comme {{ isViewingAsRecruiter ? 'Candidat' : 'Recruteur' }}
        </button>
      </div>

      <!-- mobile only -->
      <div id="mobile">
        <div class="hamburger-container" @click.stop="toggleMenu">
          <img
            v-if="isMenuOpen"
            src="@/assets/icons/close-btn.svg"
            alt="liste icône"
          />
          <img v-else src="@/assets/icons/hamburger.svg" alt="liste icône" />
        </div>
      </div>

      <!-- menu -->
      <ul :class="isMenuOpen ? 'menu' : 'menu hidden'">
        <div class="overlay" @click.stop="handleSubMenuClose"></div>
        <!-- ########### Offres d'emploi ########### -->
        <li
          :class="{
            'li-with-submenu': activeSubMenu === 'offer',
            active: activePage === 'search',
          }"
          @mouseover="handleSubMenuMouseOver('offer')"
          @mouseleave="handleSubMenuMouseLeave('offer')"
        >
          <!-- Texte pour redirection -->
          <div class="menu-item" @click.stop="gotoPage('/recherche')">
            Offres d'emploi
          </div>
          <!-- Flèche pour déplier le sous-menu -->
          <img
            v-if="isLoggedIn && userRole === 'applicant'"
            class="arrows"
            :src="
              showSubMenuOffer
                ? require('@/assets/icons/arrow-up.svg')
                : require('@/assets/Icon-black-54.svg')
            "
            @click.stop="toggleSubMenu('offer')"
          />
          <!-- ############### submenu ############### -->
          <transition name="fade">
            <ul
              v-if="isLoggedIn && userRole === 'applicant' && showSubMenuOffer"
              class="submenu"
              :class="{ show: showSubMenuOffer }"
            >
              <li
                :class="{ active: activePage === 'candidatures' }"
                @click.stop="gotoPage('/candidatures')"
              >
                Mes candidatures
              </li>
              <li
                :class="{ active: activePage === 'favorite' }"
                @click.stop="gotoPage('/favoris')"
              >
                Mes favoris
              </li>
              <li
                :class="{ active: activePage === 'Alerts' }"
                @click.stop="gotoPage('/alertes')"
              >
                Mes alertes
              </li>
            </ul>
          </transition>
        </li>

        <!-- ########### Chat IA emploi ########### -->
        <li
          v-if="isLoggedIn && userRole === 'applicant'"
          :class="{ active: activePage === 'messaging' }"
          @click.stop="gotoPage('/chat-ia')"
        >
          Chat IA emploi
        </li>

        <!-- ########### Réseau social ########### -->
        <li
          :class="{ active: activePage === 'social' }"
          @click.stop="gotoPage('/reseau-social')"
        >
          Réseau social
        </li>

        <!-- ########### Communauté ########### -->
        <li
          v-if="isLoggedIn"
          :class="{
            'li-with-submenu': activeSubMenu === 'communaute',
            active: activePage === 'recruiter-search',
          }"
          @mouseover="handleSubMenuMouseOver('communaute')"
          @mouseleave="handleSubMenuMouseLeave('communaute')"
        >
          <div
            class="badge-container"
            :class="{ 'with-badge': receivedInvitationsCount > 0 }"
          >
            <!-- Texte pour redirection -->
            <div class="menu-item" @click.stop="gotoPage('/communaute')">
              Communauté
            </div>

            <!-- Affichage du badge avec le nombre d'invitations reçues -->
            <span v-if="receivedInvitationsCount > 0" class="badge">
              {{ receivedInvitationsCount }}
            </span>
          </div>
          <!-- Flèche pour déplier le sous-menu -->
          <img
            class="arrows"
            :src="
              showSubMenuCommunaute
                ? require('@/assets/icons/arrow-up.svg')
                : require('@/assets/Icon-black-54.svg')
            "
            @click.stop="toggleSubMenu('communaute')"
          />
          <!-- ############ submenu ############ -->
          <transition name="fade">
            <ul
              v-if="showSubMenuCommunaute"
              class="submenu"
              :class="{ show: showSubMenuCommunaute }"
            >
              <li
                :class="{ active: activePage === 'friends' }"
                @click.stop="gotoPage('/amis')"
              >
                <div
                  class="badge-container"
                  :class="{ 'with-badge': receivedInvitationsCount > 0 }"
                >
                  Mes amis
                  <!-- Badge d'invitations reçues sur Mes amis -->
                  <span v-if="receivedInvitationsCount > 0" class="badge">
                    {{ receivedInvitationsCount }}
                  </span>
                </div>
              </li>
              <li
                v-if="userRole === 'recruiter'"
                :class="{ active: activePage === 'recruiter-favorite' }"
                @click.stop="gotoPage('/recruteur/favoris')"
              >
                Mes favoris
              </li>

              <li
                v-if="userRole === 'recruiter'"
                :class="{ active: activePage === 'recruiter-messaging' }"
                @click.stop="gotoPage('/recruteur/messagerie')"
              >
                Messagerie
              </li>
              <li
                v-if="userRole === 'applicant'"
                :class="{ active: activePage === 'call' }"
                @click.stop="gotoPage('/messagerie')"
              >
                Messagerie
              </li>
            </ul>
          </transition>
        </li>

        <!-- ########### Actualités ########### -->
        <li
          :class="{ active: activePage === 'blog' }"
          @click.stop="gotoPage('/actualites')"
        >
          Actualités
        </li>

        <!-- ########### Tarifs ########### -->
        <li
          :class="{ active: activePage === 'prices' }"
          @click.stop="gotoPage('/tarifs')"
        >
          Tarifs
        </li>

        <!-- ########### Mon profil ########### -->
        <li
          v-if="!isLoggedIn"
          :class="{ active: activePage === 'login', 'grey-bg': true }"
          @click.stop="gotoPage('/connexion')"
        >
          S'inscrire / Se connecter
        </li>
        <li
          v-else
          :class="{
            'li-with-submenu': activeSubMenu === 'profil',
            active:
              activePage ===
              (userRole === 'recruiter' ? 'profil-recruiter' : 'profil'),
            avatar: true,
          }"
          @mouseover="handleSubMenuMouseOver('profil')"
          @mouseleave="handleSubMenuMouseLeave('profil')"
        >
          <div class="avatar-img">
            <UserAvatar v-if="user" :user="user" :width="40" />
          </div>
          <!-- Texte pour redirection -->
          <div
            class="menu-item"
            @click.stop="
              gotoPage(
                userRole === 'recruiter' ? '/recruteur/profil' : '/profil'
              )
            "
          >
            Mon profil
          </div>

          <!-- Flèche pour déplier le sous-menu -->
          <img
            class="arrows"
            :src="
              showSubMenuProfil
                ? require('@/assets/icons/arrow-up.svg')
                : require('@/assets/Icon-black-54.svg')
            "
            @click.stop="toggleSubMenu('profil')"
          />
          <!-- ############ submenu ############ -->
          <transition name="fade">
            <ul
              v-if="showSubMenuProfil"
              class="submenu"
              :class="{ show: showSubMenuProfil }"
            >
              <li
                v-if="userRole === 'recruiter'"
                :class="{ active: activePage === 'recruiter-dashboard' }"
                @click.stop="gotoPage('/recruteur/tableau-de-bord')"
              >
                Tableau de bord
              </li>
              <li
                v-else
                :class="{ active: activePage === 'dashboard' }"
                @click.stop="gotoPage('/tableau-de-bord')"
              >
                Tableau de bord
              </li>
              <li
                v-if="userRole === 'recruiter'"
                :class="{ active: activePage === 'recruiter-offer' }"
                @click.stop="gotoPage('/recruteur/offres')"
              >
                Mes offres
              </li>
              <li
                :class="{ active: activePage === 'settings' }"
                @click.stop="gotoPage('/parametres')"
              >
                Paramètres
              </li>
              <li @click.stop="handleLogout">Se déconnecter</li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import { logout } from '@/services/account.service';
  import gotoPage from '@/utils/router';
  import { mapGetters, mapState } from 'vuex';
  import UserAvatar from '../../views-models/profil/UserAvatar.vue';

  export default {
    name: 'NavbarApp',
    components: {
      UserAvatar,
    },
    data() {
      return {
        user: this.getUser || {},
        activePage: this.$route.name || 'home', // Set active page for lasting hover effect ("breadcrumb")
        isMenuOpen: false, // Toggle state of navbar for navigation
        showSubMenuProfil: false,
        showSubMenuCommunaute: false,
        showSubMenuOffer: false,
        activeSubMenu: null,
        isViewingAsRecruiter: false, // état pour le switch
      };
    },
    async mounted() {
      try {
        this.photoUrl = this.getUser.photo;
      } catch (error) {
        console.error('Error fetching profile section data:', error);
      }
    },
    computed: {
      ...mapGetters([
        'isLoggedIn',
        'getUser',
        'userRole',
        'receivedInvitationsCount',
      ]),
      ...mapState(['receivedInvitationsCount']),

      userTypeClass() {
        if (this.isLoggedIn) {
          return this.userRole === 'recruiter' ? 'navbar-recruiter' : 'navbar';
        }
        return this.isViewingAsRecruiter ? 'navbar-recruiter' : 'navbar';
      },
    },
    watch: {
      '$route.name'(newName) {
        this.activePage = newName;
      },
      getUser: {
        immediate: true,
        handler(newUser) {
          this.user = newUser || {};
        },
      },
    },
    methods: {
      handleLogout() {
        try {
          logout();
        } catch (error) {
          console.log(error);
        }
        this.closeOverlay();
      },

      // Set active page for breadcrumb and use vue router to switch page
      gotoPage(page) {
        // Fermer tous les sous-menus
        this.showSubMenuProfil = false;
        this.showSubMenuCommunaute = false;
        this.showSubMenuOffer = false;
        this.closeOverlay();
        this.isMenuOpen = false;

        // Définir la page active
        this.activePage = page;

        // Appeler la fonction de navigation
        gotoPage(page);
      },
      // basculer entre le mode recruteur et candidat
      toggleView() {
        this.isViewingAsRecruiter = !this.isViewingAsRecruiter;
        this.$emit('view-switched', this.isViewingAsRecruiter);
      },

      // Fonction pour ouvrir/fermer le sous-menu
      handleSubMenuMouseOver(submenu) {
        if (window.innerWidth >= 768) {
          if (submenu === 'offer') {
            this.showSubMenuOffer = true;
          } else if (submenu === 'communaute') {
            this.showSubMenuCommunaute = true;
          } else if (submenu === 'profil') {
            this.showSubMenuProfil = true;
          }
        }

        // Gestion de l'overlay
        const overlay = document.querySelector('.overlay');

        if (
          this[
            `showSubMenu${submenu.charAt(0).toUpperCase() + submenu.slice(1)}`
          ]
        ) {
          overlay?.classList.add('active'); // Active l'overlay si le sous-menu est visible
        } else {
          overlay?.classList.remove('active'); // Désactive l'overlay si aucun sous-menu n'est visible
        }
      },

      handleSubMenuMouseLeave(submenu) {
        if (window.innerWidth >= 768) {
          if (submenu === 'offer') {
            this.showSubMenuOffer = false;
          } else if (submenu === 'communaute') {
            this.showSubMenuCommunaute = false;
          } else if (submenu === 'profil') {
            this.showSubMenuProfil = false;
          }
        }
      },

      // Fonction pour basculer la visibilité du sous-menu sélectionné
      toggleSubMenu(submenu) {
        // Vérifiez si le sous-menu est déjà actif
        if (this.activeSubMenu === submenu) {
          // Fermez le sous-menu si vous cliquez à nouveau
          this.activeSubMenu = null;
        } else {
          // Sinon, définissez le sous-menu comme actif
          this.activeSubMenu = submenu;
        }

        // Basculez la visibilité du sous-menu
        if (submenu === 'offer') {
          this.showSubMenuOffer = !this.showSubMenuOffer;
        } else if (submenu === 'communaute') {
          this.showSubMenuCommunaute = !this.showSubMenuCommunaute;
        } else if (submenu === 'profil') {
          this.showSubMenuProfil = !this.showSubMenuProfil;
        }

        // Mettez à jour la visibilité de l'overlay
        this.updateOverlayVisibility();
      },

      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        if (this.isMenuOpen) {
          this.showSubMenuProfil = false;
          this.showSubMenuCommunaute = false;
          this.showSubMenuOffer = false;
          this.closeOverlay();
        }
      },

      closeOverlay() {
        const overlay = document.querySelector('.overlay');
        overlay?.classList.remove('active');
      },

      handleSubMenuClose() {
        this.showSubMenuProfil = false;
        this.showSubMenuCommunaute = false;
        this.showSubMenuOffer = false;
        this.closeOverlay();
      },

      // Mettre à jour la visibilité de l'overlay
      updateOverlayVisibility() {
        const overlay = document.querySelector('.overlay');
        if (
          this.showSubMenuOffer ||
          this.showSubMenuCommunaute ||
          this.showSubMenuProfil
        ) {
          overlay?.classList.add('active'); // Affiche l'overlay si un sous-menu est ouvert
        } else {
          overlay?.classList.remove('active'); // Cache l'overlay si aucun sous-menu n'est ouvert
        }
      },
    },
  };
</script>

<style scoped>
  .beta-section h2 {
    width: -1rem;
    color: #f6b336;
    font-size: 20px;
  }

  nav {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6000;
  }

  .overlay {
    display: none; /* Par défaut, caché partout */
    z-index: 5500;
  }

  .overlay.active {
    display: block; /* S'affiche uniquement lorsqu'il est activé */
  }

  .menu li {
    position: relative; /* Permet de positionner le sous-menu par rapport à ce <li> */
  }

  /* Animation Fade */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .submenu {
    display: none; /* Cache le sous-menu par défaut */
    position: absolute; /* Positionne le sous-menu en dehors du flux normal */
    top: 100%; /* Place le sous-menu juste en dessous de l'élément parent */
    left: 0; /* Aligne le sous-menu avec le côté gauche de l'élément parent */
    background-color: var(--navbar-bg-color); /* Couleur de fond */
    z-index: 6000; /* Assurez-vous que le sous-menu est au-dessus d'autres éléments */
    color: var(--navbar-font-color);
    height: fit-content;
  }

  .submenu li {
    padding: 10px; /* Espacement des items dans le sous-menu */
    background-color: var(--navbar-bg-color);
    cursor: pointer;
    width: 100%;
    height: 56px;
  }
  .submenu li.active {
    background-color: var(--primary-1b2);
  }

  /* Affiche les sous-menus quand l'état `showSubMenu` est vrai */
  .submenu.show {
    display: block;
  }

  .menu {
    background-color: var(--navbar-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 6000;
  }

  ul {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: space-around;
  }

  li {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
  }

  li.active {
    background-color: var(--primary-1b2);
  }

  li:hover {
    background-color: var(--gray-light);
  }

  .navbar {
    position: relative;
    min-height: 80px;
    max-height: 100vh;
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--navbar-bg-color);
    color: var(--navbar-font-color);
    overflow: visible;
    z-index: 6001;
  }

  .navbar-recruiter .img-container-circle {
    background-color: var(--yellow-100);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    z-index: 6001;
  }

  .img-container {
    height: 100%;
    min-width: 205px;
    display: flex;
    align-items: flex-end;
    padding-left: 15px;
    cursor: pointer;
    z-index: 6001;
  }

  .avatar img:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .avatar img {
    height: 30px;
    width: 30px;
  }

  .avatar-img {
    border-radius: 50%;
    border: 2px solid var(--gray-light);
    margin-right: 10px;
  }

  .grey-bg {
    background-color: var(--gray-light);
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* hamburger & avatar */
  .hamburger-container {
    position: absolute;
    top: -5px;
    right: 10px;
    cursor: pointer;
    z-index: 6100;
  }
  .hamburger-container img {
    height: 50px;
  }

  #mobile {
    position: absolute;
    top: 20px;
    right: 0px;
    display: flex;
    gap: 5px;
  }

  #avatar {
    display: flex;
    height: 30px;
  }

  .hidden {
    display: none;
  }

  .badge {
    position: absolute;
    top: -5px; /* Positionnement en haut */
    right: -20px; /* Décalage vers la droite */
    background-color: red;
    color: white; /* Texte blanc pour le contraste */
    border-radius: 50%; /* Forme circulaire */
    width: 18px; /* Taille fixe */
    height: 18px;
    font-size: 12px; /* Taille de police ajustée */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Ombre légère */
    z-index: 10; /* Pour s'assurer qu'il est au-dessus du reste */
  }

  .badge-container {
    position: relative; /* Permet d'aligner le badge */
    display: inline-block; /* S'adapte à la taille du texte */
  }
  .with-badge {
    margin-right: 20px;
  }

  .arrows {
    height: 30px;
    width: 30px;
  }

  .view-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 10px;
  }
  .view-switch button {
    background-color: var(--primary-1b2);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    font-size: 09rem;
    transition:
      background-color 0.3s ease,
      transform 0.2s ease;
  }
  .view-switch button:hover {
    background-color: var(--primary-hover);
    transform: scale(1.05);
  }
  .view-switch button:active {
    background-color: var(--primary-active);
    transform: scale(0.95);
  }

  @media screen and (max-width: 768px) {
    .view-switch {
      margin-right: 5px;
    }
    .view-switch button {
      padding: 0.3rem 0.7rem;
      font-size: 0.8rem;
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1px);
      z-index: 5500;
      display: none; /* Par défaut, masqué */
      transition: opacity 0.3s ease;
    }

    .overlay.active {
      display: block; /* Affiché lorsque l'overlay est actif */
    }

    .menu .li-with-submenu {
      z-index: 5500;
    }
  }

  @media screen and (min-width: 992px) {
    ul {
      flex-direction: row;
    }

    .navbar {
      flex-direction: row;
      align-items: center;
      padding-top: 0px;
      z-index: 6001;
    }

    .menu {
      position: initial;
      display: flex;
      flex-direction: row;
      height: fit-content;
      min-height: 80px;
    }

    .submenu {
      top: 100%; /* Toujours en dessous du parent */
      left: 0;
      height: fit-content;
    }

    .hamburger-container {
      display: none;
    }

    .img-container {
      justify-content: center;
      padding-left: 0px;
      z-index: 6001;
    }

    #mobile {
      display: none;
    }
  }

  @media screen and (min-width: 1800px) {
    .navbar {
      padding: 0 15%;
      margin: auto;
    }
  }

  @media screen and (min-width: 2000px) {
    .navbar {
      padding: 0 25%;
      margin: auto;
    }

    .navbar-recruiter .img-container-circle {
      background-color: var(--yellow-100);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      width: 64px;
      z-index: 6001;
    }
  }
</style>
